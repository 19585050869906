import React from "react";
import Layout from "../layout";
import { Helmet } from "react-helmet";
import config from "../../data/SiteConfig";
import "../components/EmptyMessaging/EmptyMessaging.css";

const NotFound = () => {

    return (
        <Layout>
          <div className="404-container">
            <Helmet title={`Not Found | ${config.siteTitle}`} />
            <div className="empty-messaging-container">
                <h1 >
                    Sorry, looks like the page you were looking for couldn't be found! 
                </h1>
            </div>
          </div>
        </Layout>
    );
}

export default NotFound;